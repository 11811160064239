import React, { Component } from 'react';

import memoize from 'lodash/memoize';
import '../sass/customerAccount.css';
import CallApi from './../../../utils/CallApi';
import TableLoader from './../../../common/tableloader/TableLoader';
import searchIcon from '../img/search.svg';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import RemoveIcon from '@material-ui/icons/Remove';
import SearchSvg from '../svg/SearchSvg';
import { browserHistory } from 'react-router';
import CarteImg from '../img/solde_plein@2x.png';
import ImgRemove from '../img/delete.png';
import ImgSettings from '../img/settings.png';
import ImgTrash from '../img/trash.png';
import ImgPencil from '../img/pencil.png';
import ImgCircleCross from '../img/cross-circle-blue1.png';
import removeAccents from 'remove-accents';
import { connect } from 'react-redux';
import config from '../../../utils/config';
import priceFormatting from '../../../utils/priceFormatting';
import { addFlashMessage } from '../../../common/flashMessage/actions/flashMessages';
import Clusterize from '../components/Clusterize';
import ModalExport from '../components/ModalExport';
import axios from 'axios';
import { I18n } from 'react-redux-i18n';
import A_B_Tester from '../../../components/A_B_Tester';
import ModalHistory from '../components/ModalHistory';
import ModalSettings from '../components/ModalSettings';
import ModalAccountForm from '../components/ModalAccountForm';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const language = window.navigator.language;

class CustomerAccount extends Component {
	constructor(props) {
		super(props);
		this.state = {
			listAccounts: [],
			listAccountsFiltered: [],
			searchValue: '',
			loading: true,
			resetSearch: false,
			idOrderedBy: '',
			orderDirection: 'ASC',
			openExportModal: false,
			openExportHistory: false,
			allUsers: [],
			paymentTypes: [],
			openSettings: false,
			selectedRows: [], 
			selectAll: false,
			modalOpen: false,
			openAccountForm: false,
			selectedAccountData: null,
			hasBalance: false,
			soldeFilter: null,
			informationsFilter: null,
			visitsFilter: null,
			isFiltered: false,
		};

		this.filtersData = [
			{
				title: 'Solde',
				options: [
					{ label: I18n.t("customerAccount.customersBalance"), filter: account => account.account_details },
					{ label: I18n.t("customerAccount.customersDebitBalance"), filter: account => account.account_details.balance < 0 },
					{ label: I18n.t("customerAccount.customersZeroBalance"), filter: account => account.account_details.balance === 0 },
					{ label: I18n.t("customerAccount.customersCreditBalance"), filter: account => account.account_details.balance > 0 },
				]
			},
			{
				title: 'Informations',
				options: [
					{ label: I18n.t("customerAccount.customersInformations"), filter: account => account.customer_infos },
					{ label: I18n.t("customerAccount.phoneNumberEntered"), filter: account => account.customer_infos.details_phone },
					{ label: I18n.t("customerAccount.emailEntered"), filter: account => account.customer_infos.details_email },
					{ label: I18n.t("customerAccount.withoutEmailOrPhone"), filter: account => !account.customer_infos.details_phone && !account.customer_infos.details_email },
				]
			},
			{
				title: 'Visites',
				options: [
					{ label: I18n.t("customerAccount.customersVisits"), filter: account => account.account_details },
					{ label: I18n.t("customerAccount.noVisit"), filter: account => account.account_details.visit === 0 },
					{ label: I18n.t("customerAccount.between1And10Visits"), filter: account => account.account_details.visit >= 1 && account.account_details.visit <= 10 },
					{ label: I18n.t("customerAccount.between11And50Visits"), filter: account => account.account_details.visit >= 11 && account.account_details.visit <= 50 },
					{ label: I18n.t("customerAccount.between51And100Visits"), filter: account => account.account_details.visit >= 51 && account.account_details.visit <= 100 },
					{ label: I18n.t("customerAccount.moreThan100Visits"), filter: account => account.account_details.visit > 100 },
				]
			},
		];
	}

	componentDidMount() {
		this.getListCustomerAccounts();
		this.getAllUsers();
		this.getAllPaymentTypes();
		this.checkEspaceClientGroup();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.searchValue !== this.state.searchValue) {
			window.sessionStorage.setItem('searchValue', this.state.searchValue);
		}

		if (
			prevState.listAccounts.length !== this.state.listAccounts.length &&
			window.sessionStorage.getItem('searchValue') !== 'null'
		) {
			const searchValueStorage =
				window.sessionStorage.getItem('searchValue') !== 'null'
					? window.sessionStorage.getItem('searchValue')
					: '';
			this.setState(
				{
					searchValue: searchValueStorage,
					loading: true,
				},
				() => {
					this.filterAccounts(searchValueStorage);
				}
			);
		}

		if (prevProps.auth.user !== this.props.auth.user) {
			this.checkEspaceClientGroup();
		}
	}

	getListCustomerAccounts = async () => {
		try {
			const accessToken = await window.localStorage.getItem('accessToken');
			const accountsList = await CallApi(
				'GET',
				'customer-account',
				'',
				'',
				`accessToken ${accessToken}`
			);
			this.setState({
				listAccounts: accountsList,
				listAccountsFiltered: accountsList,
				loading: false,
			});
		} catch (e) {
			this.props.addFlashMessage({
				type: 'danger',
				text: 'Une erreur est survenue',
				persistent: false,
			});
		}
	};

	deleteSelectedAccounts = async (customerIds) => {
		try {
            const accessToken = await window.localStorage.getItem('accessToken');
           	await CallApi(
                'POST',
                'customer-account/customers/removed',
                '',
                { customerIds },
                `accessToken ${accessToken}`
            );
        } catch (error) {
            console.error('Error deleting accounts:', error);
        }
	}

	getAllUsers = async () => {
		try {
			const accesToken = await window.localStorage.getItem('accessToken');
			const getUsers = await CallApi(
				'GET',
				'user/users',
				'',
				'',
				`accessToken ${accesToken}`
			);

			this.setState({ allUsers: getUsers[0] });
		} catch (error) {
			console.log(error);
			this.props.addFlashMessage({
				type: 'danger',
				text: 'Une erreur est survenue',
				persistent: false,
			});
		}
	};

	checkEspaceClientGroup() {
		const user = this.props.auth.user;
		if (user && user.groups) {
			const hasEspaceClientGroup = user.groups.some(group => group.name === 'espace_client');
			this.setState({ hasEspaceClientGroup });
		}
	}

	getAllPaymentTypes = async () => {
		try {
			const accesToken = await window.localStorage.getItem('accessToken');
			const getPaymentTypes = await CallApi(
				'GET',
				'user/paymentTypes',
				'',
				'',
				`accessToken ${accesToken}`
			);
			this.setState({ paymentTypes: getPaymentTypes[0] });
		} catch (error) {
			console.log(error);
			this.props.addFlashMessage({
				type: 'danger',
				text: 'Une erreur est survenue',
				persistent: false,
			});
		}
	};

	searchAccounts = e => {
		e.preventDefault();
		const searchValue = e.target.value;
		this.setState({ searchValue, loading: true }, () => {
			setTimeout(() => {
				this.filterAccounts(searchValue);
			}, 500);
		});
	};

	setOrderTable = (e, field, childField, id) => {
		e.preventDefault();
		this.setState(
			(prevState, props) => ({
				loading: true,
				idOrderedBy: id,
				orderDirection:
					prevState.idOrderedBy === id && prevState.orderDirection === 'ASC'
						? 'DESC'
						: 'ASC',
			}),
			() => {
				setTimeout(
					() => this.orderTable(this.state.listAccounts, field, childField),
					500
				);
			}
		);
	};

	orderTable = (accountList, field, childField) => {
		const sort_by = (field, fieldInObject, reverse = 'ASC', primer) => {
			const key = function (x) {
				const val =
					fieldInObject !== null && x[field.toString()]
						? field === 'companies'
							? x[field.toString()].length > 1
								? 'ALL'
								: x[field.toString()][0][fieldInObject]
							: x[field.toString()][fieldInObject]
						: x[field];
				return primer ? primer(val) : val;
			};

			const order = reverse === 'ASC' ? 1 : -1;
			return function (a, b) {
				return (
					(a = key(a) === null ? '' : key(a)),
					(b = key(b) === null ? '' : key(b)),
					order * ((a > b) - (b > a))
				);
			};
		};

		const sortedData = accountList[0].sort(
			sort_by(field, childField, this.state.orderDirection, a =>
				typeof a === 'string' ? a.toUpperCase() : a
			)
		);

		this.setState({
			loading: false,
			listAccountsFiltered: [sortedData],
		});
	};

	filterAccounts = searchValue => {
		const { listAccounts, soldeFilter, informationsFilter, visitsFilter } = this.state;
	
		const soldeFilterFunction = this.filtersData[0]?.options[soldeFilter]?.filter;
		const informationsFilterFunction = this.filtersData[1]?.options[informationsFilter]?.filter;
		const visitsFilterFunction = this.filtersData[2]?.options[visitsFilter]?.filter;
	
		const accountsListFiltered = listAccounts.flat().filter(account => {
			const search = account?.search?.toLowerCase() || '';
			const searchValueLower = searchValue ? searchValue.toLowerCase() : '';
	
			return (
				(!searchValue || search.includes(searchValueLower)) &&
				(!soldeFilterFunction || soldeFilterFunction(account)) &&
				(!informationsFilterFunction || informationsFilterFunction(account)) &&
				(!visitsFilterFunction || visitsFilterFunction(account))
			);
		});
	
		this.setState({
			loading: false,
			listAccountsFiltered: [accountsListFiltered],
			selectedRows: [],
			selectAll: false,
		});
	};
	

	handleModalExport = e => {
		this.setState({ openExportModal: true });
	};

	handleResetClick = e => {
		e.preventDefault();
		this.setState(
			{
				searchValue: '',
				resetSearch: true,
				loading: true,
				listAccountsFiltered: this.state.listAccounts,
			},
			() => {
				this.filterAccounts('');
            	this.setState({ loading: false });
			}
		);
	};

	handleSelect = (e) => {
		const { id, value } = e.target;
	
		const filterKeyObject = {
			soldeSelect: 'soldeFilter',
			informationsSelect: 'informationsFilter',
			visitsSelect: 'visitsFilter',
		};
	
		const filterKey = filterKeyObject[id];
	
		if (filterKey) {
			this.setState(
				{
					[filterKey]: value,
					isFiltered: true,
				},
				() => {
					this.filterAccounts(this.state.searchValue);
				}
			);
		}
	};

	handleClearFilters = () => {
		this.setState(
			{ 
				isFiltered: false,
				soldeFilter: null,
				informationsFilter: null,
				visitsFilter: null,
				listAccountsFiltered: this.state.listAccounts
			},
			() => {
				this.filterAccounts(this.state.searchValue);
            	this.setState({ loading: false });
			}
		);
	}

	handleCheckboxChange = (id) => {
		this.setState(prevState => {
			const isAlreadySelected = prevState.selectedRows.includes(id);
			
			const newSelectedRows = isAlreadySelected
				? prevState.selectedRows.filter(rowId => rowId !== id)
				: [...prevState.selectedRows, id];
			
			const selectedAccount = !isAlreadySelected && prevState.listAccountsFiltered
				.flat()
				.find(account => account.id === id);
	
			const hasBalance = !isAlreadySelected && selectedAccount?.account_details?.balance > 0 || selectedAccount?.account_details?.balance < 0;
	
			return {
				selectedRows: newSelectedRows,
				selectedAccountData: selectedAccount,
				hasBalance: hasBalance
			};
		});
	};

	handleSelectAll = () => {
		const data = this.state.listAccountsFiltered.flat();
		const dataWithoutBalance = data.filter(account => account.account_details.balance === 0 || account.account_details.balance === null);
		
		const selectAll = !this.state.selectAll;
		const newSelectedRows = selectAll ? dataWithoutBalance.map(row => row.id) : [];
		
		this.setState({
			selectedRows: newSelectedRows,
			selectAll: selectAll,
		});
	}

	isRowSelected = (id) => {
		return this.state.selectedRows.includes(id);
	}

	handleDelete = async () => {
		const selectedIds = this.state.selectedRows;
		try {
            await this.deleteSelectedAccounts(selectedIds);
			const updatedList = this.state.listAccountsFiltered[0]
				? this.state.listAccountsFiltered[0].filter(
					account => !selectedIds.includes(account.id)
				)
				: [];

			this.setState({
				listAccountsFiltered: [updatedList],
				selectedRows: [],
				modalOpen: false,
			});
			this.props.addFlashMessage({
				type: 'success',
				text: "Votre sélection a été supprimée avec succès.",
				persistent: false,
			});

        } catch(error) {
            console.error("Error during delete :", error);
			this.props.addFlashMessage({
				type: 'danger',
				text: 'Une erreur est survenue lors de la supression.',
				persistent: false,
			});
        }
	};

	handleOpenModal = () => {
		this.setState({ modalOpen: true });
	};

	handleCloseModal = () => {
		this.setState({
			modalOpen: false,
		});
	};

	handleOpenModalWithData = () => {
		this.setState({
			openAccountForm: true, 
		});
	}

	handleFormSuccess = async () => {
		try {
			await this.getListCustomerAccounts();
			this.setState({ 
				openAccountForm: false,
				selectedRows: []
			});
			this.props.addFlashMessage({
				type: 'success',
				text: "Le compte a été modifié avec succès.",
				persistent: false,
			});
		} catch(error) {
			console.error("Error during update :", error);
			this.props.addFlashMessage({
				type: 'danger',
				text: 'Une erreur est survenue lors de la mise à jour des informations.',
				persistent: false,
			});
		}
	};

	exportCustomerData = async (
		startDate = '',
		endDate = '',
		route,
		userId = '',
		method,
		email
	) => {
		try {
			const accessToken = await window.localStorage.getItem('accessToken');

			const customerAccountExport = await CallApi(
				method,
				`customer-account/${route}`,
				'',
				{
					startDate,
					endDate,
					email,
				},
				`accessToken ${accessToken}`
			);

			if (customerAccountExport && customerAccountExport.length > 0) {
				let message =
					customerAccountExport[0].ok && customerAccountExport[0].data
						? I18n.t('lneExport.exportProcessing')
						: I18n.t('lneExport.noData');
				this.props.addFlashMessage({
					type: message.type,
					text: message,
					persistent: false,
				});
			}
		} catch (error) {
			console.log(error);
			this.props.addFlashMessage({
				type: 'error',
				text: "Une erreur s'est produite",
				persistent: true,
			});
			return error;
		}
	};

	createAccountRow = () => {
		const data = this.state.searchValue || this.state.isFiltered ?  this.state.listAccountsFiltered.flat() : this.state.listAccountsFiltered.flat().slice(0, 100);
		const renderCompanies = companies => {
			return companies.length > 1 ? 'Tous' : companies[0].company_name;
		};

		if (data.length > 0) {
			return data.map(account => {
				const accountIds = account.companies.map(company => company.uniq_id_customer);
				const accountIdCompanies = account.companies.map(company => company.id);
				const nameRestaurantTmp =
					account.companies.length === 1
						? account.companies[0].company_name.split(' ').join('-')
						: '';
				const nameRestaurant = removeAccents(nameRestaurantTmp);
		
				return (
					<tr
						className="flex-table"
						role="rowgroup"
						key={account.id}
						data-url={account.companies.length === 1
						? `customer-account/${accountIds}?id=${accountIdCompanies}`
						: `customer-account/${accountIds}`}
					>
						{this.props.isSuite &&
							<td className="flex-row check-box-cell" role="cell">
								<input
									type="checkbox"
									data-id={account.id}
									checked={this.isRowSelected(account.id)}
									onChange={() => this.handleCheckboxChange(account.id)}
								/>
							</td>
						}
						{!this.props.isSuite &&
							<td className="flex-row small" role="cell">
								<a href={account.companies.length === 1
									? `customer-account/${accountIds}?id=${accountIdCompanies}`
									: `customer-account/${accountIds}`}>
									<span>{account.card_number || ''}</span>
								</a>
							</td>
						}
						<td className="flex-row big" role="cell">
							<a href={account.companies.length === 1
								? `customer-account/${accountIds}?id=${accountIdCompanies}`
								: `customer-account/${accountIds}`}>
								<span>{`${account.lastname || ''} ${account.firstname || ''}`}</span>
							</a>
						</td>
						<td className="flex-row big" role="cell">
							<a href={account.companies.length === 1
								? `customer-account/${accountIds}?id=${accountIdCompanies}`
								: `customer-account/${accountIds}`}>
								<span>{account.customer_infos?.details_email || ''}</span>
							</a>
						</td>
						<td className="flex-row big" role="cell">
							<a href={account.companies.length === 1
								? `customer-account/${accountIds}?id=${accountIdCompanies}`
								: `customer-account/${accountIds}`}>
								<span>{account.customer_infos?.details_phone || ''}</span>
							</a>
						</td>
						<td className="flex-row big" role="cell">
							<a href={account.companies.length === 1
								? `customer-account/${accountIds}?id=${accountIdCompanies}`
								: `customer-account/${accountIds}`}>
								<span>{account.customer_infos?.address || ''}</span>
							</a>
						</td>
						<td className="flex-row small" role="cell">
							<a href={account.companies.length === 1
								? `customer-account/${accountIds}?id=${accountIdCompanies}`
								: `customer-account/${accountIds}`}>
								<span>{account.customer_infos?.customer_company_id || ''}</span>
							</a>
						</td>
						<td className="flex-row big" role="cell">
							<a href={account.companies.length === 1
								? `customer-account/${accountIds}?id=${accountIdCompanies}`
								: `customer-account/${accountIds}`}>
								<span>{account.companies ? renderCompanies(account.companies) : ''}</span>
							</a>
						</td>
						<td className="flex-row small" role="cell">
							<a href={account.companies.length === 1
								? `customer-account/${accountIds}?id=${accountIdCompanies}`
								: `customer-account/${accountIds}`}>
								<span>{account.account_details?.balance ? priceFormatting(account.account_details.balance, 'en', 'EUR') : ''}</span>
							</a>
						</td>
					</tr>
				);
			});
		} else {
			return (
				<div id="empty-contact">
					<div id="error-box">
						<div className="face">
							<div className="eye" />
							<div className="eye right" />
							<div className="mouth sad" />
						</div>
						<div className="shadow move" />
						<p className="message">
							No account found
						</p>
					</div>
				</div>
			);
		}
	};

	render() {
		const headersLabels = [
			{
				id: 'card_number',
				cssClass: 'small',
				field: 'card_number',
				childField: null,
				label: `${I18n.t('customerAccount.cardNumber')}`,
			},
			{
				id: 'lastname',
				cssClass: 'big',
				field: 'lastname',
				childField: null,
				label: `${I18n.t('customerAccount.firstAndLastName')}`,
			},
			{
				id: 'details_email',
				cssClass: 'big',
				field: 'customer_infos',
				childField: 'details_email',
				label: `${I18n.t('customerAccount.email')}`,
			},
			{
				id: 'details_phone',
				cssClass: 'big',
				field: 'customer_infos',
				childField: 'details_phone',
				label: `${I18n.t('customerAccount.phoneNumber')}`,
			},
			{
				id: 'adress',
				cssClass: 'big',
				field: 'customer_infos',
				childField: 'adress',
				label: `${I18n.t('customerAccount.address')}`,
			},
			{
				id: 'customer_company_id',
				cssClass: 'small',
				field: 'customer_infos',
				childField: 'customer_company_id',
				label: `${I18n.t('customerAccount.siren')}`,
			},
			{
				id: 'company_name',
				cssClass: 'big',
				field: 'companies',
				childField: 'company_name',
				label: `${I18n.t('customerAccount.companyName')}`,
			},
			{
				id: 'balance',
				cssClass: 'small',
				field: 'account_details',
				childField: 'balance',
				label: `${I18n.t('customerAccount.balance')}`,
			},
		];

		const selectFilter = () => {
			const soldeOptions = this.filtersData[0]?.options;
			const informationsOptions = this.filtersData[1]?.options;
			const visitsOptions = this.filtersData[2]?.options;
		
			return (
				<div className="flex items-center">
					<div className="select-container">
						{this.state.soldeFilter &&
							<label htmlFor="soldeSelect" className="select-label">Solde</label>
						}
						<select id="soldeSelect" className={this.state.soldeFilter ? "filter-select select-selected" : "filter-select select-unselected"} onChange={this.handleSelect} value={this.state.soldeFilter || ""}>
							<option value="" disabled>Solde</option>
							{soldeOptions.map((option, index) => (
								<option key={index} value={index}>
									{option.label}
								</option>
							))}
						</select>
					</div>
					<div className="select-container">
						{this.state.informationsFilter &&
							<label htmlFor="informationsSelect" className="select-label">Informations</label>
						}
						<select id="informationsSelect" className={this.state.informationsFilter ? "filter-select select-selected" : "filter-select select-unselected"} onChange={this.handleSelect} value={this.state.informationsFilter || ""}>
							<option value="" disabled>Informations</option>
							{informationsOptions.map((option, index) => (
								<option key={index} value={index}>
									{option.label}
								</option>
							))}
						</select>
					</div>
					<div className="select-container">
						{this.state.visitsFilter &&
							<label htmlFor="visitsSelect" className="select-label">Visites</label>
						}
						<select id="visitsSelect" className={this.state.visitsFilter ? "filter-select select-selected" : "filter-select select-unselected"} onChange={this.handleSelect} value={this.state.visitsFilter || ""}>
							<option value="" disabled>Visites</option>
							{visitsOptions.map((option, index) => (
								<option key={index} value={index}>
									{option.label}
								</option>
							))}
						</select>
					</div>
					{this.state.isFiltered &&
						<button className="reset-filters-btn" onClick={() => this.handleClearFilters()}>
							<img src={ImgCircleCross} alt="Close" className='cross-circle'/>
							<p>Supprimer les filtres</p>
						</button>
					}
				</div>
			);
		};

		const createTableHeader = () => {
			return headersLabels
			.filter(head => {
				if (this.props.isSuite && head.id === 'card_number') {
				  return false;
				}
				return true;
			})
			.map(head => {
				return (
					<div
						id="header_label"
						key={`${Math.floor(Math.random() * 100000)}`}
						className={`flex-row ${head.cssClass}`}>
						<p
							key={head.id}
							role="columnheader"
							onClick={e =>
								this.setOrderTable(e, head.field, head.childField, head.id)
							}>
							{head.label}
						</p>
						{head.id !== this.state.idOrderedBy ? (
							<RemoveIcon />
						) : this.state.orderDirection === 'ASC' ? (
							<ArrowUpwardIcon />
						) : (
							<ArrowDownwardIcon />
						)}
					</div>
				);
			});
		};

		return (
            <div id="customer-account">
                <h3>{I18n.t("customerAccount.customerAccountTitle")}</h3>
                <header>
                    <div className="header-main">
                        <div className="customer-header-left">
                            {selectFilter()}
                        </div>
                        <div className="customer-header-action">
                            <button
                                type="button"
                                onClick={() =>
                                    this.setState({ openSettings: true })
                                }
                            >
                                <img
                                    src={ImgSettings}
                                    alt="Settings"
                                    className="settings"
                                />
                            </button>
                            {/* { !this.props.isSuite &&
								<button
									className="btn-history"
									onClick={() => this.setState({ openExportHistory: true })}>
									Historique
								</button>
							} */}
                            {/* <A_B_Tester
								featureFlag={'export-customer-account'}
								FeatureFlagComponent={
									<button
										className="header-export-btn"
										// onClick={this.handleModalExport}
									>
										{I18n.t('customerAccount.export')}
									</button>
								}
								DefaultComponent={null}
							/> */}
                            <a
                                className="link-history"
                                href="https://laddition.slite.page/p/pAnyxHbOz_zgIk/Historique-des-comptes-clients"
                                target="_blank"
                            >
                                Historique
                            </a>

                            <div className="search-bar-account">
                                {this.state.searchValue ? (
                                    <img
                                        src={ImgRemove}
                                        onClick={this.handleResetClick}
                                        className="remove"
                                        alt="img remove"
                                    />
                                ) : (
                                    ""
                                )}
                                <input
                                    type="text"
                                    placeholder={I18n.t(
                                        "customerAccount.search"
                                    )}
                                    value={
                                        this.state.searchValue
                                            ? this.state.searchValue
                                            : ""
                                    }
                                    onChange={this.searchAccounts}
                                />
                                <SearchSvg />
                            </div>
                        </div>
                    </div>
                </header>
                {this.state.selectedRows.length > 0 && this.props.isSuite && (
                    <div className="row-actions">
                        <p>
                            {this.state.selectedRows.length > 1
                                ? `${this.state.selectedRows.length} comptes clients sélectionnés`
                                : `${this.state.selectedRows.length} compte client sélectionné`}
                        </p>
                        <div className="row-buttons">
                            {this.state.selectedRows.length === 1 && (
                                <button
                                    className="btn-update"
                                    type="button"
                                    onClick={this.handleOpenModalWithData}
                                >
                                    <p>Modifier</p>
                                    <img
                                        src={ImgPencil}
                                        alt="Update"
                                        className="pencil"
                                    />
                                </button>
                            )}
                            {!this.state.hasBalance && (
                                <button
                                    className="btn-delete"
                                    type="button"
                                    onClick={this.handleOpenModal}
                                >
                                    <p>Supprimer</p>
                                    <img
                                        src={ImgTrash}
                                        alt="Delete"
                                        className="trash"
                                    />
                                </button>
                            )}
                        </div>
                    </div>
                )}
                <div className="table-header" role="rowgroup">
                    {this.props.isSuite && (
                        <th
                            id="header_label"
                            className="flex-row check-box-header"
                        >
                            <input
                                type="checkbox"
                                checked={this.state.selectAll}
                                onChange={this.handleSelectAll}
                            />
                        </th>
                    )}
                    {createTableHeader()}
                </div>

                <main className="main_customer_account">
                    <section>
                        {this.state.loading ? (
                            <TableLoader />
                        ) : this.state.listAccountsFiltered[0] &&
                          this.state.listAccountsFiltered[0].length > 0 ? (
                            <Clusterize
                                rows={this.createAccountRow()}
                                onCheckboxChange={this.handleCheckboxChange}
                            />
                        ) : (
                            <div id="empty-contact">
                                <div id="error-box">
                                    <div className="face">
                                        ¨
                                        <div className="eye" />
                                        <div className="eye right" />
                                        <div className="mouth sad" />
                                    </div>
                                    <div className="shadow move" />
                                    <p className="message">
                                        {I18n.t(
                                            "customerAccount.noAccountFound"
                                        )}
                                    </p>
                                </div>
                            </div>
                        )}
                    </section>
                </main>

                <Dialog
                    open={this.state.modalOpen}
                    onClose={this.handleCloseModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Supprimer des comptes clients
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Voulez-vous vraiment supprimer cette sélection de
                            comptes clients ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseModal}>Annuler</Button>
                        <Button onClick={this.handleDelete} autoFocus>
                            Valider
                        </Button>
                    </DialogActions>
                </Dialog>

                <ModalExport
                    openModal={this.state.openExportModal}
                    user={this.props.auth.user}
                    onClose={() => {
                        this.setState({ openExportModal: false });
                    }}
                    exportfct={this.exportCustomerData.bind(this)}
                />

                <ModalHistory
                    openModal={this.state.openExportHistory}
                    user={this.props.auth.user}
                    companies={this.props.companies}
                    allUsers={this.state.allUsers}
                    paymentTypes={this.state.paymentTypes}
                    onDisplayMessage={this.props.addFlashMessage}
                    onClose={() => {
                        this.setState({ openExportHistory: false });
                    }}
                    onError={() => {
                        this.props.addFlashMessage({
                            type: "danger",
                            text: "Une erreur est survenue",
                            persistent: false,
                        });
                    }}
                />

                <ModalSettings
                    openModal={this.state.openSettings}
                    onClose={() => {
                        this.setState({ openSettings: false });
                    }}
					onSuccess={() => {
                        this.props.addFlashMessage({
							type: 'success',
							text: I18n.t('customerAccount.settingsSavedWithSuccess'),
							persistent: false,
                        });
                    }}
					onError={() => {
                        this.props.addFlashMessage({
                            type: "danger",
							text: I18n.t('accountMigration.error'),
                            persistent: false,
                        });
                    }}
                />

                <ModalAccountForm
                    openModal={this.state.openAccountForm}
                    onClose={() => {
                        this.setState({ openAccountForm: false });
                    }}
                    onSuccess={this.handleFormSuccess}
                    accountData={this.state.selectedAccountData}
                />
            </div>
        );
	}
}

function mapStateToProps(state) {
	const user = state.auth.user
	let isSuite = false;
	if (user && user.groups && Array.isArray(user.groups)) {
		isSuite = user.groups.some(
			group => group.name === 'laddition-suite'
		);
		
	}
	return {
		auth: state.auth,
		companies: state.entities.informations.undefined.companies,
		isSuite
	};
}




export default connect(mapStateToProps, { addFlashMessage })(CustomerAccount);
